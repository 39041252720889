import { createContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import MensagemApp from '../servicos/config/MensagemApp'
import ProfissionalAPI from '../servicos/ProfissionalAPI'
import { IconContext } from 'react-icons/lib'

export const ContextoGeral = createContext({})
ContextoGeral.displayName = 'contexto geral'

const theme = createTheme({
	palette: {
		action: {
			disabledBackground: "grey",
			disabled: "black"
		},
		text: {
			primary: '#000000'
		},
		primary: {
			main: '#000000',
			contrastText: '#fff'
		}
	}
})

export function ContextoGeralProvider({ children }) {
	const [dados, setDados] = useState({})

	const navegar = useNavigate()

	async function deslogar() {
		let host = ""

		let retorno = await ProfissionalAPI.alterarStatus('OF', dados)

		if (retorno?.resultado) {
			if (!!window.AndroidInterface) {
				if (typeof window.AndroidInterface.stopBackgroundLocation === "function") {
					host = window.AndroidInterface.recoverdomain()
					window.AndroidInterface.saveToken('')
					window.AndroidInterface.stopBackgroundLocation()
					window.AndroidInterface.showAndroidVersion(MensagemApp.deslogado)
				}

			} else alert(MensagemApp.deslogado)

			setDados({})
			navegar('/?dominio=' + host)
		} else {
			if (retorno?.resultado) {
				if (typeof window.AndroidInterface !== 'undefined') {
					if (typeof window.AndroidInterface.showAndroidVersion === "function") {

						if (retorno?.mensagem) {
							window.AndroidInterface.showAndroidVersion(retorno.mensagem)
						}
					}

					navegar('/app/home')

				} else try {
					window.AndroidInterface.showAndroidVersion(retorno.mensagem)
				} catch (err) {
					alert(err)
				}
			} else try {
				window.AndroidInterface.showAndroidVersion(retorno.mensagem)
			} catch (err) {
				alert(err)
			}
		}
	}

	useEffect(() => {
		if (dados != undefined) {
			if (window.location.pathname == "/relatar")
				return
			if (!!dados?.config?.corPrincipal) {
				theme.palette.primary.main = dados.config.corPrincipal
				theme.palette.text.primary = dados.config.corPrincipal
			}

			if (Object.keys(dados).length === 0) {
				if (!!window.AndroidInterface) {
					if (typeof window.AndroidInterface.recoverToken === "function") {

						let token = window.AndroidInterface.recoverToken()

						if (token !== '') {
							if (typeof window.AndroidInterface.recoverdomain === "function") {
								let host = window.AndroidInterface.recoverdomain()
								if (host != '') {
									let rota = `/?dominio=${host}`
									navegar(rota)
								}
							}
						}
					}
				} else {
					navegar('/')
				}
			} else if (dados.dominio === undefined) {
				navegar('/')
			}
		} else {
			navegar('/')
		}
	}, [dados])

	const [searchParams, setSearchParams] = useSearchParams()

	if (dados == {}) {
		if (!!window.AndroidInterface) {
			if (typeof window.AndroidInterface.recoverdomain === "function") {
				setDados(dados => ({
					...dados,
					dominio: window.AndroidInterface.recoverdomain()
				}))
			}
		}
	}

	return <ContextoGeral.Provider value={{ dados, setDados, deslogar }}>
		<IconContext.Provider value={{ style: { verticalAlign: 'center' } }}>
			<ThemeProvider theme={theme}>
				{children}
			</ThemeProvider>
		</IconContext.Provider>
	</ContextoGeral.Provider>
}