import { Alert, Badge, Button, Card, Checkbox, CircularProgress, Collapse, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Skeleton, Switch, TextField } from "@mui/material"
import { useEffect, useState, useRef } from "react"
import axios from "axios"
import { AttachEmail, AttachFile } from "@mui/icons-material"

export default function Relatar() {
	const [formData, setFormData] = useState({
		nomeCompleto: '',
		telefone: '',
		empresa: '',
		site: '',
		siteOnline: false,
		problemaOutrosSistemas: '',
		ambiente: [],
		descricaoProblema: '',
		imagens: [],
		video: []
	})

	function filterURL() {
		switch (window.location.host) {
			case "localhost:3000":
				return "http://localhost:3001"
			case "appprofhumb.mototaxionline.com":
			case "appprofteste.rj.r.appspot.com":
				return "https://node-dot-appprofteste.rj.r.appspot.com"
			default:
				return "https://appprofnodeprod.mototaxionline.com"
		}
	}

	const [domErr, setDomErr] = useState(false)
	const [loadMsg, setLoadMsg] = useState(true)
	const [msg, setMsg] = useState("")
	const [validate, setValidate] = useState(false)
	const [validateCard, setValidateCard] = useState({
		show: false,
		msg: "",
		success: false
	})

	const containerRef = useRef(null)

	const [msgs, setMsgs] = useState([])

	const [finit, setFinit] = useState(0)

	const handleChange = (e) => {
		setDomErr(false)
		const { name, value, type, files, checked } = e.target
		if (name == "video" && formData.video.length == 1)
			return
		if (name == "site" && formData.video.length == 1)
			if (value.includes(" "))
				return
			else setFormData(p => ({ ...p, site: value }))
		if (name == "ambiente")
			setFormData({
				...formData,
				ambiente: ["site", "painel", "aplicativo"].map(item => {
					console.log(item)
					if (checked && item == value)
						return item
					else if (formData.ambiente.includes(item) && item != value)
						return item
				})
			})
		else setFormData({
			...formData,
			[name]: type === 'file'
				? [...formData[name], ...files]
				: value,
		})
	}


	const handleSubmit = (e) => {
		setMsg("")
		setFinit(1)
		e.preventDefault()
		if (formData) {
			const data = new FormData()
			data.append("nome", formData.nomeCompleto)
			data.append("telefone", formData.telefone)
			data.append("empresa", formData.empresa)
			data.append("site", formData.site)
			data.append("siteOnline", formData.siteOnline)
			data.append("problema", formData.problemaOutrosSistemas)
			data.append("ambiente", formData.ambiente)
			data.append("descricao", formData.descricaoProblema)
			if (formData.imagens)
				Array.from(formData.imagens).forEach(file => data.append("imagens", file))
			if (formData.video)
				Array.from(formData.video).forEach(file => data.append("video", file))

			axios.post(filterURL() + "/relatarDiscord", data, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}).then(r => {
				if (r.status == 200)
					setFinit(3)
			}).catch(r => {
				setFinit(2)
				if (r?.response?.data?.msg)
					setMsg(r.response.data?.msg)
			})
		}
	}

	useEffect(() => {
		document.title = "Relatar problema"
		axios.get(filterURL() + "/bot/mensagens").then(r => {
			if (r.status == 200)
				setMsgs(r.data)
		}).catch(r => {
			console.error(r)
		}).finally(() => setLoadMsg(false))
	}, [])

	useEffect(() => {
		if (msgs.length > 0 && containerRef.current)
			containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior: "smooth" })
	}, [msgs])

	useEffect(() => {
		setValidateCard(prev => ({ ...prev, show: false }))
		if (formData.site && formData.siteOnline)
			setFormData(prev => ({ ...prev, siteOnline: false }))
	}, [formData.site])

	useEffect(() => {
		if (formData.site != "" && formData.siteOnline) {
			setValidate(true)
			axios.post(filterURL() + "/testarDominio", {
				dominio: formData.site
			}).then(r => {
				setValidateCard({
					show: true,
					msg: r.data
						? "Seu domínio está acessível, favor continue apenas caso seja realmente necessário."
						: "Seu domínio parece estar offline, conclua o formulário abaixo.",
					success: r.data
				})
			}).catch(r => {
				setValidateCard({
					show: true,
					msg: "Não foi possível acessar o domínio! Confirme se o domínio está correto antes de prosseguir.",
					success: false
				})
			}).finally(() => setValidate(false))
		}
	}, [formData.siteOnline])
	const isPhone = window.innerWidth < 1081
	let count = 0

	return <div style={{ zoom: isPhone ? 1 : 0.75 }}>
		<div style={{
			position: "relative",
			padding: "1rem",
			background: "#333756",
			color: "white",
			zIndex: 500
		}}>
			<img
				style={{ position: "absolute", top: "1rem", left: isPhone ? "50%" : "1rem", transform: isPhone ? "translate(-50%)" : "none" }}
				src="https://mappsistemas.com.br/paginas/wp-content/themes/sitemapp/assets/img/logo/white_logo.png"
			/>
			<h1 style={{
				marginTop: isPhone ? "5rem" : 0
			}} className="text-center">Informar Indisponibilidade do Sistema</h1>
			<h5 className="text-center">Solicitações emergenciais, somente para indisponibilidades</h5>
		</div>
		<div className="p-3" style={{
			maxWidth: "1024px",
			margin: "0 auto",
			transition: "1s"
		}}>
			<Collapse in={finit < 3}>
				<div>
					<h4 className="mb-2">Últimas mensagens</h4>
					<div>
						<Collapse in={loadMsg}>
							<Skeleton variant="rounded" height={200} />
						</Collapse>
						<Collapse in={!loadMsg}>
							<div ref={containerRef} style={{ maxHeight: "66vh", overflow: "auto", marginBottom: "1rem" }}>

								<div className="my-2 d-flex flex-column-reverse gap-2">
									{msgs.length > 0
										? msgs.map(msg => {
											if (msg.author == "urgencia-suporte") {
												return <Card className="p-2 bg-danger-subtle text-danger w-75 lh-1">
													<div style={{ fontWeight: 400 }}>
														Relatos de erro: {msg.count}
													</div>
													<div className="text-end mt-1" style={{ fontSize: "18px", fontWeight: 700 }}>
														{msg.date}
													</div>
												</Card>
											} else if (msg.author == "botmapp") {
												return <Card className="p-2 bg-success-subtle text-success w-75 ms-auto lh-1">
													<div style={{ fontWeight: 400 }}>
														{msg.msg}
													</div>
													<div className="text-end mt-1" style={{ fontSize: "18px", fontWeight: 700 }}>
														{msg.date}
													</div>
												</Card>
											}
										})
										: <Card className="p-2 bg-info-subtle text-info">
											Nenhum problema relatado
										</Card>}
								</div>
							</div>
						</Collapse>
					</div>

					<h4 className="mb-2">Formulário</h4>
					<form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
						<TextField size="small" label="Nome completo" name="nomeCompleto" value={formData.nomeCompleto} onChange={handleChange} required fullWidth />
						<TextField size="small" label="Telefone" name="telefone" value={formData.telefone} onChange={handleChange} required fullWidth />
						<TextField size="small" label="Nome da empresa" name="empresa" value={formData.empresa} onChange={handleChange} required fullWidth />
						<TextField size="small" label="Site (domínio)" name="site" value={formData.site} onChange={handleChange}
							onBlur={e => setDomErr(!/^[^\s]+\.[^\s]+$/g.test(e.target.value))}
							error={domErr} required fullWidth />
						<div>
							<FormControlLabel control={<Switch
								// disabled={formData.site.length == 0}
								checked={formData.siteOnline}
								onChange={() => setFormData(prev => ({ ...prev, siteOnline: !prev.siteOnline }))}
							/>} label="Meu sistema está fora do ar" />
							<Collapse in={validate}>
								<CircularProgress />
							</Collapse>
							<Collapse in={validateCard.show}>
								<Alert severity={validateCard.success ? "success" : "warning"}>
									{validateCard.msg}
								</Alert>
							</Collapse>
						</div>

						<fieldset className="d-flex flex-column gap-3">
							<h5>Perguntas de Triagem</h5>
							<div>
								<label>
									O problema ocorre somente no sistema da Mapp, ou em outros sistemas também?
								</label>
								<TextField size="small" multiline name="problemaOutrosSistemas" value={formData.problemaOutrosSistemas} onChange={handleChange} required fullWidth />
							</div>

							<div>
								<label>O problema ocorre em qual ambiente?</label>
								<RadioGroup>
									<FormControlLabel name="ambiente" checked={formData.ambiente.includes("site")} value="site" control={<Checkbox />} onChange={handleChange} label="Site" />
									<FormControlLabel name="ambiente" checked={formData.ambiente.includes("painel")} value="painel" control={<Checkbox />} onChange={handleChange} label="Painel" />
									<FormControlLabel name="ambiente" checked={formData.ambiente.includes("aplicativo")} value="aplicativo" control={<Checkbox />} onChange={handleChange} label="Aplicativo" />
								</RadioGroup>
							</div>
						</fieldset>

						<div>
							<label className="alert alert-info">
								Por favor, adicione até <b>3 prints</b> que mostrem claramente o problema para agilizar a solução. Para enviar vídeos considere enviá-los para um serviço como <a href="https://streamable.com/" target="_blank">Streamable</a> e adicione os links na descrição do problema.
							</label>
							<div className="d-flex gap-3">
								<div>
									<input
										accept="image/*"
										style={{ display: 'none' }}
										id="img-btn"
										name="imagens"
										onChange={handleChange}
										multiple
										disabled={formData.imagens.length >= 3}
										type="file"
									/>
									<div className="mt-3">
										<label htmlFor="img-btn">
											<Badge badgeContent={formData?.imagens && Array.from(formData.imagens).length} color="primary">
												<Button
													component="span"
													className={formData.imagens.length >= 3 ? "opacity-50" : ""}
													disabled={formData.imagens.length >= 3}
												>
													<AttachFile />
													Adicionar Imagem
												</Button>
											</Badge>
										</label>
									</div>
								</div>
							</div>
						</div>

						<div>
							<label>
								Descrição do Problema
							</label>
							<TextField multiline
								size="small"
								rows={6}
								name="descricaoProblema"
								value={formData.descricaoProblema} onChange={handleChange}
								placeholder="Faça uma descrição detalhada do problema."
								required
								fullWidth />
						</div>

						<div className="alert alert-info">
							<h3>Solicitação de Emergência</h3>
							<p>Nossos engenheiros receberão um alerta imediato comunicando este problema para atendimento em tempo real. Utilize esta opção apenas para situações emergenciais como a indisponibilidade parcial ou total de seu serviço. Este tipo de chamado pode gerar custos adicionais em sua fatura caso não seja por indisponibilidade de serviços.</p>
							<h3>Uso Exclusivo Fora do Horário Comercial</h3>
							<p>Este portal destina-se exclusivamente ao uso após as 18:00 durante a semana e aos finais de semana. Durante o horário comercial, para contato direto e rápido com nossa equipe, utilize WhatsApp ou telefone.</p>
						</div>

						<Button className="mb-5 p-2" sx={{ background: "#333756" }} variant="contained" type="submit" disabled={finit == 1}>
							{finit == 1
								? <CircularProgress />
								: "Enviar Solicitação"}
						</Button>
					</form>
				</div>
			</Collapse>
			<Collapse in={finit == 3}>
				<Alert>Sucesso!</Alert>
			</Collapse>
			<Collapse in={finit == 2}>
				<Card className="p-3 text-center mt-3" style={{
					background: "#f66",
					color: "#400"
				}}>
					<div className="h1">
						<b>Erro!</b>
					</div>
					<div>
						{msg ?? "Não foi possível enviar as informações solicitadas, lamentamos."}
					</div>
					<Button className="mt-3" onClick={() => setFinit(0)}>Recarregar</Button>
				</Card>
			</Collapse>
		</div>
	</div>
}